import { Injectable } from '@angular/core';
import { SIDEBAR_MENU, SidebarMenu } from '../data/sidebar-menu';

const LOCAL_SIDEBAR_MENU = 'sidebar_menu';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor() {}

  setSidebar(value: SidebarMenu): void {
    localStorage.setItem(LOCAL_SIDEBAR_MENU, JSON.stringify(value));
  }

  getSidebar(): SidebarMenu {
    const sidebar = localStorage.getItem(LOCAL_SIDEBAR_MENU);
    if (sidebar && JSON.parse(sidebar)?.version === SIDEBAR_MENU.version) {
      return JSON.parse(sidebar);
    }
    return SIDEBAR_MENU;
  }

  removeSidebarMenu(): void {
    localStorage.removeItem(LOCAL_SIDEBAR_MENU);
  }
}
