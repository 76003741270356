import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DOMAIN_ACCOUNTING,
  DOMAIN_CNAE,
  DOMAIN_COMPANY,
  DOMAIN_EXPORT,
  DOMAIN_MACHINERY,
  DOMAIN_OAUTH,
  DOMAIN_OND,
  DOMAIN_PGC,
} from '@app/data/constants/domains';
import { SidebarService } from '@app/layouts/admin-layout/components/sidebar/services/sidebar.service';
import { CompanyService } from '@app/modules/company/services/company.service';
import { IAccount } from '@models/IAccount';
import { DatabaseProvider } from '@paella-front/ngx-database';
import { Database } from '@paella-front/ngx-database/lib/database';
import { SuperHttpClientProvider } from '@paella-front/ngx-super-http-client';
import { SuperHttpClient } from '@paella-front/ngx-super-http-client/lib/super-http-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { ServerErrorService } from './server-error.service';

interface Token {
  current: number;
  jwt: string;
  refresh: string;
  type: string;
}

const STORAGE_TOKEN = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _token$: BehaviorSubject<Token> = new BehaviorSubject(null);
  private _account$: BehaviorSubject<IAccount> = new BehaviorSubject(null);

  constructor(
    private $db: DatabaseProvider,
    private $$shttp: SuperHttpClientProvider,
    private router: Router,
    public $serverError: ServerErrorService,
    private $api: ApiService,
    private $company: CompanyService,
    private sidebarService: SidebarService
  ) {}

  //////////////////////
  // Public Accessors //
  //////////////////////

  get token$(): Observable<Token> {
    return this._token$.asObservable();
  }

  get account$(): Observable<IAccount> {
    return this._account$.asObservable();
  }

  //////////////////////
  // Public Functions //
  //////////////////////

  getErrorResponse(errorResponse: HttpErrorResponse): string {
    if (
      errorResponse.error &&
      errorResponse.error.length > 0 &&
      errorResponse.error[0].message
    ) {
      return errorResponse.error[0].message;
    } else if (errorResponse.error && Object.keys(errorResponse.error).length > 0) {
      return errorResponse.error[Object.keys(errorResponse.error)[0]];
    }
    return 'Ocurrió un error inesperado';
  }

  setAccount$(_account: IAccount | null): void {
    this._account$.next(_account);
  }

  async getToken(): Promise<Token> {
    return await (this.$db.default as Database).get(STORAGE_TOKEN);
  }

  isOauthUrl(url: string): boolean {
    return url.includes(this.$shttp.BASE_URL);
  }

  async isSignedIn(): Promise<boolean> {
    const token = await this.getToken();

    if (token) {
      if (!this.$chttp.headers.get('Authorization')) {
        await this.setToken(token);
      }

      return true;
    }
    return false;
  }

  /**
   * data = { email: email; }
   */
  async recovery(data: unknown): Promise<void> {
    try {
      await this.$shttp.post<unknown>('/open/recovery', data).toPromise();
    } catch (error) {
      await this.logout();
      throw error;
    }
  }

  /**
   * @param {any} data.code string
   * @param {any} data.password string
   *
   * ----- OR -----
   *
   * @param {any} data.username string
   */
  async recover(data: unknown): Promise<void> {
    try {
      await this.$shttp.put<unknown>('/open/recovery', data).toPromise();
    } catch (error) {
      await this.logout();
      throw error;
    }
  }

  async refreshToken(): Promise<Token> {
    const oldToken = await this.getToken();
    if (!oldToken) {
      throw new Error('AuthService.refreshToken(): No token found!');
    }

    const data = { refresh: oldToken.refresh };
    const newToken = await this.$shttp.post<Token>('/open/refresh', data).toPromise();
    await this.setToken(newToken);

    return newToken;
  }

  login(username: string, password: string): Observable<Token> {
    return this.$shttp.post<Token>(`/open/login`, { username, password }).pipe(
      map((response: Token) => {
        this.setToken(response);
        return response;
      })
    );
  }

  register(
    isAcceptedTerms = true,
    username: string,
    password: string,
    token: string
  ): Observable<boolean> {
    return this.$shttp.put<boolean>(`/open/invite/${token}`, {
      isAcceptedTerms,
      username,
      password,
    });
  }

  getAccountByToken(token: string): Observable<IAccount> {
    return this.$shttp.get(`/open/invite/${token}`);
  }

  async logout(redirect = false): Promise<void> {
    await this.setToken(null);
    this.$company.company = null;
    this.$api.setUser$(null);
    this.$api.setRole$(null);
    this.sidebarService.removeSidebarMenu();
    if (redirect) {
      this.router.navigate(['/', 'login']);
    }
  }

  getMe(): Observable<IAccount> {
    return this.$shttp.get<IAccount>(`/basic/account/me`).pipe(
      map((account: IAccount) => {
        this.setAccount$(account);
        return account;
      })
    );
  }

  ///////////////////////
  // Private Functions //
  ///////////////////////

  private async setToken(value: Token): Promise<void> {
    await (this.$db.default as Database).set(STORAGE_TOKEN, value);
    if (value) {
      this.$shttp.headers.set('Authorization', `${value.type} ${value.jwt}`);
      this.$chttp.headers.set('Authorization', `${value.type} ${value.jwt}`);
      this.$ohttp.headers.set('Authorization', `${value.type} ${value.jwt}`);
      this.$mhttp.headers.set('Authorization', `${value.type} ${value.jwt}`);
      this.$exhttp.headers.set('Authorization', `${value.type} ${value.jwt}`);
      this.$cnaehttp.headers.set('Authorization', `${value.type} ${value.jwt}`);
      this.$contabilidad.headers.set('Authorization', `${value.type} ${value.jwt}`);
      this.$pgc.headers.set('Authorization', `${value.type} ${value.jwt}`);
    } else {
      this.$shttp.headers.reset();
      this.$chttp.headers.reset();
      this.$ohttp.headers.reset();
      this.$mhttp.headers.reset();
      this.$exhttp.headers.reset();
      this.$cnaehttp.headers.reset();
      this.$contabilidad.headers.reset();
      this.$pgc.headers.reset();
    }

    this._token$.next(value);
  }

  ///////////////////////
  // Private Accessors //
  ///////////////////////

  private get $shttp(): SuperHttpClient {
    return this.$$shttp.use(DOMAIN_OAUTH);
  }

  private get $chttp(): SuperHttpClient {
    return this.$$shttp.use(DOMAIN_COMPANY);
  }

  private get $ohttp(): SuperHttpClient {
    return this.$$shttp.use(DOMAIN_OND);
  }

  private get $exhttp(): SuperHttpClient {
    return this.$$shttp.use(DOMAIN_EXPORT);
  }

  private get $cnaehttp(): SuperHttpClient {
    return this.$$shttp.use(DOMAIN_CNAE);
  }

  private get $pgc(): SuperHttpClient {
    return this.$$shttp.use(DOMAIN_PGC);
  }

  private get $contabilidad(): SuperHttpClient {
    return this.$$shttp.use(DOMAIN_ACCOUNTING);
  }

  private get $mhttp(): SuperHttpClient {
    return this.$$shttp.use(DOMAIN_MACHINERY);
  }
}
